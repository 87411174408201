<div id="content">
  <div id="left" class="responsivePanel">

    <div class="dualityDiv">
      <h2>First Duality</h2>

      <div id="inline">
        <a id="toggleButton" (click)="duality1ShowText=true;" [ngClass]="{ 'active': duality1ShowText }">Text</a>
        <a id="toggleButton" (click)="duality1ShowText=false;" [ngClass]="{ 'active': !duality1ShowText }">Image</a>
      </div>

      <input id="duality1TextInput" *ngIf="duality1ShowText" placeholder="Duality 1" [value]="duality1Text" (input)="duality1Text = $event.target.value"
      />

      <div id="image1" style="text-align:center; width: 100%" *ngIf="!duality1ShowText">
        <input type="file" accept="image/*" (change)="file1ChangeEvent($event)" /><br>
        <image-cropper *ngIf="image1ChangedEvent" style="display: inline-block;" [imageChangedEvent]="image1ChangedEvent" [maintainAspectRatio]="true"
          [aspectRatio]="1 / 1" [resizeToWidth]="100" format="png" (imageCropped)="image1Cropped($event)" (imageLoaded)="imageLoaded()"
          (loadImageFailed)="loadImageFailed()"></image-cropper>
      </div>

    </div>

    <div class="dualityDiv">
      <h2>Second Duality</h2>

      <div id="inline">
        <a id="toggleButton" (click)="duality2ShowText=true;" [ngClass]="{ 'active': duality2ShowText }">Text</a>
        <a id="toggleButton" (click)="duality2ShowText=false;" [ngClass]="{ 'active': !duality2ShowText }">Image</a>
      </div>

      <input id="duality2TextInput" *ngIf="duality2ShowText" placeholder="Duality 2" [value]="duality2Text" (input)="duality2Text = $event.target.value"
      />

      <div id="image2" style="text-align:center; width: 100%;" *ngIf="!duality2ShowText">
        <input #file1 type="file" accept="image/*" (change)="file2ChangeEvent($event)" />
        <image-cropper *ngIf="image2ChangedEvent" style="display: inline-block;" [imageChangedEvent]="image2ChangedEvent" [maintainAspectRatio]="true"
          [aspectRatio]="1 / 1" [resizeToWidth]="100" format="png" (imageCropped)="image2Cropped($event)" (imageLoaded)="imageLoaded()"
          (loadImageFailed)="loadImageFailed()"></image-cropper>
      </div>

    </div>

    <button class="createManButton" (click)="onClickGenerate(); scroll(right)">Create Man</button>
    <button class="createManButton" (click)="onClickDownload()">Download Man</button>

  </div>

  <div id="right" #right class="responsivePanel">
    <canvas id="myCanvas" width=574 height=964 hidden=true></canvas>
    <img id="dualityPic" class="dualityPic" src={{dualityImageSource}}/>
  </div>
</div>