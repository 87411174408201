<div class="scrollDiv">

  <div class="container">
    <h2>“It is every person’s god-given right to experience the Duality of Man.”</h2>
    <p>- Jarod Aerts, Founder of DualityOfMan.pw</p>

    <br>

    <h2>What is the Duality of Man?</h2>
    <p>This is a question I get a lot. Most of the time people are first confronted with a Duality of Man meme they have
      no idea what to think.</p>
    <p>“Is it like Bionicles?”</p>
    <p>“What is going on?”</p>
    <p>These are common questions that get asked in first encounters with the Duality of Man. People don’t immediately
      understand that the Duality of Man is so much more. The greatest thinkers have been pondering the answer to the
      question “What is the Duality of Man?” for millennia. Socrates, Confucius, Locke, Voltaire, they all tried to find
      the answer to the question, and they all failed. Though by a lucky miracle we at DualityOfMan.pw recently found
      the answer to this question: there is no answer. You see, the Duality of Man is whatever you envision it to be. It
      is whatever you want it to be. For some, the Duality of Man is good and evil. For others it is academic and
      business writing. And yet for others, the Duality of Man is potato and not potato. The Duality of Man at the same
      time encompasses both everything and nothing. It is both in a state of existence and nonextant. The Duality of Man
      is everything that does and does not define each and every one of us. DualityOfMan.pw was created to help spread
      the Duality of Man to all people because the Duality of Man is not something that belongs to any one person,
      organization, or country. The Duality of Man belongs to all humanity and should be held in common by all. It is
      our duty as members of the human race to help spread and share the Duality of Man with all those who have not
      experienced it.
    </p>

    <br>

    <h2>Why can’t I make an account?</h2>
    <p>Like mentioned above, DualityOfMan.pw believes that Duality of Man memes belong to all of humanity. By allowing
      accounts we not only put at risk your privacy, but all entice people to lay claims to this meme or that. There are
      no accounts, and so every meme you make is stored 100% anonymously for all others to see. At the moment the
      ability to see all memes is not finished, but it is in progress. When you create a meme on DualityOfMan.pw, you
      are not creating a meme for yourself. You are creating a meme for all of us.</p>

    <br>

    <h2>About the Creator</h2>
    <img id="meImg" src="../../assets/me.jpg" style="width:50%; border-radius: 10%;" />
    <p>My name is Jarod Aerts. I grew up on a farm in the middle of Nebraska and currently live and attend school in
      Lincoln, Nebraska. Before college, I spent most of my days playing high school sports, doing woodworking, and
      helping my dad on the family farm. I now mostly sit a desk, program, and run. I am studying computer science and
      business there at the University of Nebraska-Lincoln Raikes School. I am a software developer with a non-zero
      amount of experience. I worked at Deliveron Consulting Services in 2018: a consulting company with a focus on the
      Microsoft stack and agile. I am currently an intern at Microsoft in Seattle where I work on Azure cloud services.
      I built this website after the Duality of man meme became a phenomenon in my cohort at the Raikes school.</p>

    <p>I do hope that you enjoy the site, but I know that not everything is perfect. If you see any bugs, have any
      suggestions for the website, or want to help with development feel free to email me <a
        href="mailto:admin@dualityofman.pw">here.</a></p>

    <h5>And if you are feeling generous and want to help keep DualityOfMan.pw up and running please consider donating
      with the button below.</h5>
    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
      <input type="hidden" name="cmd" value="_donations" />
      <input type="hidden" name="business" value="LGNCPXQ97YNWJ" />
      <input type="hidden" name="currency_code" value="USD" />
      <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit"
        title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
      <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
    </form>

  </div>

</div>