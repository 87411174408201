<nav class="navbar navbar-expand-md navbar-dark">
    <div class="d-flex w-50 order-0">
        <a class="navbar-brand mr-1" routerLink="">Duality of Man</a>
    </div>
    <button class="navbar-toggler justify-content-center" type="button"
     data-toggle="collapse" data-target="#collapsingNavbar" (click)="toggleNavbar()">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse justify-content-center order-2" 
    [ngClass]="{ 'show': navbarOpen }" id="collapsingNavbar">
        <ul class="navbar-nav">
            <li class="nav-item" [ngClass]="{ 'active': createSelected }">
                <a class="nav-link" routerLink="">Create</a>
            </li>
            <!-- <li class="nav-item" [ngClass]="{ 'active': browseSelected }">
                <a class="nav-link" routerLink="" (click)="browseNavButtonClicked()">Browse</a>
            </li> -->
            <li class="nav-item" [ngClass]="{ 'active': aboutSelected }">
                <a class="nav-link" routerLink="about">About</a>
            </li>
        </ul>
    </div>

    <span class="navbar-text small text-truncate mt-1 w-50 text-right order-1 order-md-last"></span>
</nav>